(function ($) {
	'use strict';
	let carousel = {};

	function init() {
		let $creatorCarousel = $('.creators-carousel');
		let $seasonButtonContainer = $('.season-button-container');
		let seasonButton = '';
		if (carousel.slick) {
			carousel.slick('unslick');
		}
		carousel = $creatorCarousel.slick({
			dots: true,
			slidesToShow: 4,
			arrows: false,
			slidesToScroll: 1,
			autoplay: true,
			//autoplaySpeed: 2000,
			centerMode: false,
			centerPadding: '40px',
			responsive: [
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 2
					}
				},
				{
					breakpoint: 600,
					settings: {
						slidesToShow: 1
					}
				},
				{
					breakpoint: 480,
					settings: {
						slidesToShow: 1
					}
				}
			]
		});
		if ($('.season-button-container').length > 0) {
			$creatorCarousel.addClass('hide');
			$creatorCarousel.last().removeClass('hide');
		}
		for (let index = $creatorCarousel.length; index > 0; index--) {
			seasonButton += `<div data-carousel="${index}" class="season-button ${index == 2 ? 'active' : ''}">Season ${index}</div>`;
		}
		$seasonButtonContainer.html(seasonButton);
		$('[data-carousel]').on('click', function () {
			let index = $(this).data('carousel');
			$creatorCarousel.addClass('hide');
			$('.season-button').removeClass('active');
			$(this).addClass('active');
			$(`#carousel-${index - 1}`).removeClass('hide');
			$creatorCarousel.slick('refresh');
		})
	}
	// wait fo the block to render before loading the script in the admin panel
	if (window.acf) {
		window.acf.addAction('render_block_preview/type=instax-creators-block', function () {
			init();
		});
	} else {
		init();
	}
}(jQuery))
